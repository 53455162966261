
import { getThrottleErrorMinutes } from "@/application/util/ThrottleErrorResponse";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { mapGetters } from "vuex";
import Vue, { VueConstructor } from "vue";
import { AxiosError, AxiosResponse } from "axios";
import KBtn from "@/components/KBtn.vue";
import postRedirect from "@/application/util/postRedirect";
import { acceptInvitation } from "@/modules/authorisation/api/invitation";

interface ComponentData {
  alertType: string;
  alertMessage: string;
  isLoading: boolean;
  email: string;
  password: string;
  passwordConfirmation: string;
  isValid: boolean;
  isRedirecting: boolean;
}

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: {
          validate: () => void;
        };
      };
    }
  >
).extend({
  name: "InvitationAccept",
  components: {
    KBtn,
    KFieldGroup,
    KTextField,
  },
  data: (): ComponentData => ({
    alertType: "success",
    alertMessage: "",
    isLoading: false,
    email: "",
    password: "",
    passwordConfirmation: "",
    isValid: false,
    isRedirecting: false,
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "authorisation/isLoggedIn",
      firstError: "error/first",
    }),
  },
  created() {
    this.obtainEmailFromUrl();
  },
  methods: {
    obtainEmailFromUrl(): void {
      if (
        this.$route.query.email &&
        typeof this.$route.query.email === "string"
      ) {
        this.email = this.$route.query.email;
        return;
      }

      this.$router.push({
        name: "login",
      });

      throw new Error("invitationAccept::created no email present");
    },
    async handleAccept() {
      this.isLoading = true;
      try {
        const response = await acceptInvitation(
          this.email,
          this.$route.params.token,
          this.password,
          this.passwordConfirmation
        );
        this.isRedirecting = true;
        this.redirectToAuthDispense(response.data.data.token);
      } catch (error) {
        this.alertType = "error";
        const { response } = error as AxiosError;
        const { status } = response as AxiosResponse;

        if (status === 429) {
          this.alertMessage = this.$t("errors.429", {
            minutes: getThrottleErrorMinutes(response as AxiosResponse),
          }) as string;
        } else if (status === 400) {
          this.alertMessage = this.$t(
            "authorisation.invitationAccept.errorMessage"
          ) as string;
        }

        this.$refs.form.validate();
      } finally {
        this.isLoading = false;
      }
    },
    redirectToAuthDispense(token: string): void {
      postRedirect("auth/dispense", {
        redirect_uri: "dashboard",
        email: this.email,
        token: token,
      });
    },
  },
});
