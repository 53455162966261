import { post } from "@/application/api";
import { AxiosResponse } from "axios";

export const acceptInvitation = (
  email: string,
  token: string,
  password: string,
  passwordConfirmation: string
): Promise<AxiosResponse> => {
  return post("invitation/accept", {
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
    token: token,
  });
};
